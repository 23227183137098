// services/jobs.js
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchJobs = async () => {
  try {
    const [response1, response2] = await Promise.all([
      axios.get(`${BASE_URL}jobs/`),
      axios.get(`${BASE_URL}job_list/`)
    ]);

    const jobsData = Array.isArray(response2.data.Jobs) ? response2.data.Jobs : [response2.data.Jobs];
    const combinedData = response1.data.concat(jobsData);


    return combinedData;
  } catch (error) {
    console.error('There was an error fetching the jobs', error);
    throw error;
  }
};


export const fetchJobDetails = async () => {
  try {
    const response1 = await axios.get(`${BASE_URL}jobs/`);
    const combinedData = response1.data;

    const locationMap = {};
    const currencyMap = {};
    const companyMap = {};
    const industryMap = {};
    const categoryMap = {};
    const codingLanguageMap = {};
    const languageRequirementMap = {};

    combinedData.forEach(job => {
      if (job.location && job.location_ids) {
        locationMap[job.location] = job.location_ids;
      }
      if (job.currency && job.currency_ids) {
        currencyMap[job.currency] = job.currency_ids;
      }
      if (job.company_name && job.company_name_ids) {
        companyMap[job.company_name] = job.company_name_ids;
      }
      if (job.industry && job.industry_ids) {
        industryMap[job.industry] = job.industry_ids;
      }
      if (job.category && job.category_ids) {
        categoryMap[job.category] = job.category_ids;
      }
      if (job.coding_languages && job.coding_languages_ids) {
        job.coding_languages.forEach((lang, index) => {
          codingLanguageMap[lang] = job.coding_languages_ids[index];
        });
      }
      if (job.language_requirements && job.language_requirements_ids) {
        job.language_requirements.forEach((langReq, index) => {
          languageRequirementMap[langReq] = job.language_requirements_ids[index];
        });
      }
    });

    const locations = [...new Set(combinedData.map(job => job.location))].filter(Boolean);
    const companies = [...new Set(combinedData.map(job => job.company_name))].filter(Boolean);
    const industries = [...new Set(combinedData.map(job => job.industry))].filter(Boolean);
    const categories = [...new Set(combinedData.map(job => job.category))].filter(Boolean);
    const currencies = [...new Set(combinedData.map(job => job.currency))].filter(Boolean);
    const codingLanguages = [...new Set(combinedData.flatMap(job => job.coding_languages || []))].filter(Boolean);
    const languageRequirements = [...new Set(combinedData.flatMap(job => job.language_requirements || []))].filter(Boolean);
    console.log('Location Map:', locationMap);
    return {
      locations: locations.map(location => ({ id: locationMap[location], name: location })),
      companies: companies.map(company => ({ id: companyMap[company], name: company })),
      industries: industries.map(industry => ({ id: industryMap[industry], name: industry })),
      categories: categories.map(category => ({ id: categoryMap[category], name: category })),
      currencies: currencies.map(currency => ({ id: currencyMap[currency], name: currency })),
      codingLanguages: codingLanguages.map(lang => ({ id: codingLanguageMap[lang], name: lang })),
      languageRequirements: languageRequirements.map(langReq => ({ id: languageRequirementMap[langReq], name: langReq }))
    };

  } catch (error) {
    console.error('There was an error fetching the jobs', error);
    throw error;
  }
};

export const fetchJobById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}jobs/${id}/`);
    console.log("Job details:", response.data);
    return response.data;
  } catch (error) {
    console.error(`There was an error fetching the job with ID ${id}`, error);
    throw error;
  }
};

export const createJob = async (jobData) => {
  const token = localStorage.getItem('authToken');
  console.log('job data:', jobData); // Debugging log
  const preparedData = {
    job_title: jobData.jobTitle,
    company_name: jobData.company==='other'? null : jobData.company,
    location: jobData.location === 'other' ? null : jobData.location,
    categories: jobData.categories ==='other' ? null : jobData.categories,
    industry: jobData.industry === 'other' ? null : jobData.industry,
    currency: jobData.currency === 'other' ? null : jobData.currency,
    contract_type: jobData.contractType, // Ensure this matches the expected field
    duration: parseInt(jobData.duration, 10), // Ensure duration is an integer
    month: jobData.month,
    job_description: jobData.jobDescription,
    notes_feedbacks: jobData.notesFeedbacks,
    language_requirements: jobData.languageRequirements.map(id => parseInt(id, 10)),
    expected_salary: jobData.expectedSalary === NaN ? null : parseInt(jobData.expectedSalary, 10),
    coding_languages: jobData.codingLanguages.map(id => parseInt(id, 10)), // Ensure coding_languages is an array of IDs
    asset_class: jobData.assetClass,
    interview_report_link: jobData.interviewReportLink,
    contact_persons: jobData.contactPersons, 
    contact_interns: jobData.contactInterns, // Should be an array of objects
    new_company_name: jobData.newCompanyName,
    new_city_name: jobData.newCityName,
    new_industry_name: jobData.newIndustryName,
    new_category_name: jobData.newCategoryName,
    new_currency_code: jobData.newCurrencyCode
  };

  console.log('Prepared Data:', preparedData); // Debugging log

  const response = await fetch(`${BASE_URL}add_jobs/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(preparedData)
  });

  if (!response.ok) {
    const errorData = await response.json();
    console.error('Failed to create job', errorData);
    throw new Error('Failed to create job');
  }

  return response.json();
};
