import React from 'react';

const Header = () => {
  return (
    <header className="bg-blue-500 shadow-lg py-4">
      <div className="max-w-7xl mx-auto flex items-center justify-between px-4">
        <div className="flex items-center space-x-4">
          <img
            src="/assets/images/logo.png"
            alt="Logo"
            className="h-12 w-auto"
          />
        </div>
        <nav className="flex space-x-6">
          <a 
            href="https://derivativesfinance.fr/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-white text-lg hover:text-gray-200 transition duration-300"
          >
            Home
          </a>
          <a 
            href="https://cours.derivativesfinance.fr/courses/?loop_content_only=false&column_per_row=3&course_per_page=6&show_pagination=false&course_order=course_title_az&course_filter=true&loop_content_only=false&column_per_row=3&course_per_page=6&show_pagination=false&current_page=1&action=tutor_course_filter_ajax" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-white text-lg hover:text-gray-200 transition duration-300"
          >
            Courses
          </a>
          <a 
            href="https://forum.derivativesfinance.fr/forum/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-white text-lg hover:text-gray-200 transition duration-300"
          >
            Forum
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
