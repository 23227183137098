import React from 'react';

const MainContent = ({ children }) => {
  return (
<div className="flex-1 p-8 bg-blue-50"> {/* Add padding around the content */}
      {children}
    </div>
  );
};

export default MainContent;
