import React, { useState, useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import OpportunityTableHead from '../components/opportunities/OpportunityTableHead';
import OpportunityTableRow from '../components/opportunities/OpportunityTableRow';
import OpportunityTableToolbar from '../components/opportunities/OpportunityTableToolbar';
import TableNoData from '../components/opportunities/TableNoData';
import { fetchJobs } from '../services/jobs';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="w-16 h-16 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
  </div>
);

const Opportunities = ({ navigateToTab, canAccessOpportunityDetails, userRole }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [searchQuery, setSearchQuery] = useState('');
  const [opportunities, setOpportunities] = useState([]);
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    location: '',
    job_type: '',
    company_name: '',
    opportunity_type: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const data = await fetchJobs();
        // Add opportunity_type based on the presence of URL
        const opportunitiesWithTypes = data.map(opportunity => ({
          ...opportunity,
          opportunity_type: opportunity.url ? 'external' : 'internal',
        }));
        setOpportunities(opportunitiesWithTypes);
        setFilteredOpportunities(opportunitiesWithTypes);
        setLoading(false); // End loading
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
        setLoading(false); // End loading even on error
      }
    };
    fetchData();
  }, []);

  const applyFiltersAndSearch = (opportunities) => {
    return opportunities.filter((opportunity) => {
      const matchesSearch = searchQuery ? opportunity.title.toLowerCase().includes(searchQuery.toLowerCase()) : true;
      const matchesFilters = Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        return opportunity[key]?.toString().toLowerCase().includes(value.toLowerCase());
      });
      return matchesSearch && matchesFilters;
    });
  };

  useEffect(() => {
    const filtered = applyFiltersAndSearch(opportunities);
    setFilteredOpportunities(filtered);
  }, [opportunities, searchQuery, filters]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    setPage(0);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(0);
  };

  const handleResetFilters = () => {
    setFilters({
      location: '',
      job_type: '',
      company_name: '',
      opportunity_type: '',
    });
    setSearchQuery('');
    setPage(0);
  };

  const sortedRowsPerPageOptions = canAccessOpportunityDetails ? [10, 25, 50, 100] : [10];

  const displayedOpportunities = applyFiltersAndSearch(filteredOpportunities)
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div className="flex flex-col">
      <h1 className="text-blue-500 mb-4 text-left pb-5">Opportunities</h1>
      <div className="mb-2 p-2 bg-gray-100 border-b border-gray-200 sm:rounded-lg">
        <OpportunityTableToolbar
          filters={filters}
          setFilters={setFilters}
          onSearchChange={handleSearchChange}
          onFilterChange={handleFilterChange}
          onResetFilters={handleResetFilters}
          canAccessOpportunityDetails={canAccessOpportunityDetails}
          opportunities={canAccessOpportunityDetails ? filteredOpportunities : displayedOpportunities}
        />
      </div>
      <div className="w-full">
        <div className="align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-black">
              <OpportunityTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
           <tbody className="bg-white divide-y divide-gray-200">
  {displayedOpportunities.length > 0 ? (
    displayedOpportunities.map((opportunity) => (
      <OpportunityTableRow key={opportunity.id} opportunity={opportunity} canAccessOpportunityDetails={canAccessOpportunityDetails} />
    ))
  ) : (
    <TableNoData colSpan={5} />
  )}
</tbody>

            </table>
            {canAccessOpportunityDetails && (
              <TablePagination
                rowsPerPageOptions={sortedRowsPerPageOptions}
                component="div"
                count={filteredOpportunities.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default Opportunities;
