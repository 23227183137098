import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import AddOpportunityForm from '../../pages/AddOpportunityForm';

const OpportunityTableToolbar = ({
  filters, setFilters, onSearchChange, onFilterChange, onResetFilters, canAccessOpportunityDetails, opportunities,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [uniqueCompanies, setUniqueCompanies] = useState([]);
  const [uniqueContractTypes, setUniqueContractTypes] = useState([]);

  useEffect(() => {
    const updateFilterOptions = (data) => {
      const locations = [...new Set(data.map(item => item.location).filter(Boolean))].sort();
      const companies = [...new Set(data.map(item => item.company_name).filter(Boolean))].sort();
      const contractTypes = [...new Set(data.map(item => item.contract_type).filter(Boolean))].sort();

      setUniqueLocations(locations);
      setUniqueCompanies(companies);
      setUniqueContractTypes(contractTypes);
    };

    updateFilterOptions(opportunities);
  }, [opportunities]);

  const handleFilterChange = (selectedOption, { name }) => {
    const newFilters = {
      ...filters,
      [name]: selectedOption ? selectedOption.value : '',
    };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleResetFilters = () => {
    const resetFilters = {
      location: '',
      company_name: '',
      opportunity_type: '', 
      contract_type: '',
    };
    setFilters(resetFilters);
    onResetFilters(resetFilters);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    onSearchChange(event.target.value);
  };

  const selectStyles = {
    control: styles => ({
      ...styles,
      minWidth: 100,
      maxWidth: 150,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }),
    menu: styles => ({
      ...styles,
      zIndex: 999,
      maxHeight: '200px'
    }),
    menuList: styles => ({
      ...styles,
      maxHeight: '200px',
      overflowY: 'auto'
    })
  };

  const makeSelectOptions = list => list.map(item => ({ value: item, label: item }));

  useEffect(() => {
    if (!canAccessOpportunityDetails) {
      setSearchTerm('');
      handleResetFilters();
    }
  }, [canAccessOpportunityDetails]);

  return (
    <div className="flex flex-wrap justify-between items-center p-2 w-full gap-2">
      <div className="flex flex-wrap gap-2 w-full sm:w-auto">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 pl-3 border border-gray-300 rounded-md text-sm h-8"
        />
        <Select
          name="company_name"
          options={makeSelectOptions(uniqueCompanies)}
          onChange={handleFilterChange}
          styles={selectStyles}
          menuPortalTarget={document.body}
          placeholder="All Companies"
          isClearable
        />
        <Select
          name="location"
          options={makeSelectOptions(uniqueLocations)}
          onChange={handleFilterChange}
          styles={selectStyles}
          menuPortalTarget={document.body}
          placeholder="All Locations"
          isClearable
        />
        <Select
          name="opportunity_type"
          options={[
            { value: 'external', label: 'External' },
            { value: 'internal', label: 'Internal' },
          ]}
          onChange={handleFilterChange}
          styles={selectStyles}
          menuPortalTarget={document.body}
          placeholder="Opportunity Type"
          isClearable
        />
        <Select
          name="contract_type"
          options={makeSelectOptions(uniqueContractTypes)}
          onChange={handleFilterChange}
          styles={selectStyles}
          menuPortalTarget={document.body}
          placeholder="Contract Type"
          isClearable
        />
      </div>
      <div className="flex gap-2 mt-2 sm:mt-0">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm h-8"
          onClick={() => setIsAddFormVisible(true)}
        >
          Add Opportunity
        </button>
        <button
          className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded text-sm h-8"
          onClick={handleResetFilters}
        >
          Reset Filters
        </button>
      </div>
      {isAddFormVisible && (
        <AddOpportunityForm closeForm={() => setIsAddFormVisible(false)} />
      )}
    </div>
  );
};

export default OpportunityTableToolbar;
