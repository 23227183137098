import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchJobById } from "../services/jobs";
import DOMPurify from "dompurify";
import { FaLinkedin, FaEnvelope, FaCalendarAlt, FaMapMarkerAlt, FaBuilding } from "react-icons/fa";
import { Link } from "react-router-dom";

// Loading Spinner Component
const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="w-16 h-16 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
  </div>
);

const ScheduleSVG = () => (
  <svg
    className="h-6 w-6 text-white"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 2v2m12 0v2m-6 0v2m0 12v2m0-8v2m0-8V7c0-1.105-.895-2-2-2H5c-1.105 0-2 .895-2 2v14c0 1.105.895 2 2 2h14c1.105 0 2-.895 2-2V7c0-1.105-.895-2-2-2h-3V2z"
    ></path>
  </svg>
);

const OpportunityDetailsPage = () => {
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchOpportunity = async () => {
      try {
        const foundOpportunity = await fetchJobById(id);
        setSelectedOpportunity(foundOpportunity);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch the opportunity with ID:", id, error);
        setLoading(false);
      }
    };

    fetchOpportunity();
  }, [id]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!selectedOpportunity) {
    return (
      <div className="container mx-auto p-4 mt-4">
        <h4 className="text-2xl font-bold mb-2">Opportunity Not Found</h4>
        <p>
          We couldn't find the opportunity with ID {id}. Please check other opportunities.
        </p>
      </div>
    );
  }

  const ContactCard = ({ contact }) => (
    <div className="flex flex-col items-center">
      <div className="w-30 h-24 bg-blue-50 rounded-full mb-3 flex items-center justify-center overflow-hidden">
        <img
          src={contact.imageSrc}
          alt={contact.name}
          className="w-full h-full object-cover"
        />
      </div>
      <p className="font-bold text-center">{contact.name}</p>
      <p className="text-center">{contact.role}</p>
      <div className="flex mt-3">
        <a
          href={contact.linkedinUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600"
        >
          <FaLinkedin size={20} />
        </a>
        <a href={contact.emailUrl} className="text-blue-800 ml-2">
          <FaEnvelope size={20} />
        </a>
      </div>
    </div>
  );

  const PotentialContactsSection = () => {
    const buildContacts = () => {
      const contacts = [];
      if (selectedOpportunity) {
        selectedOpportunity.contact_persons.forEach((person, index) => {
          contacts.push({
            name: person.name,
            role: "Contact Person",
            linkedinUrl: person.linkedin,
            emailUrl: `mailto:${person.email}`,
            imageSrc: "/assets/images/avatar_1.jpg", // Update accordingly
          });
        });

        selectedOpportunity.contact_interns.forEach((intern, index) => {
          contacts.push({
            name: intern.name,
            role: "Contact Intern",
            linkedinUrl: intern.linkedin,
            emailUrl: `mailto:${intern.email}`,
            imageSrc: "/assets/images/avatar_1.jpg", // Update accordingly
          });
        });
      }
      return contacts;
    };

    const contacts = buildContacts();

    if (!contacts.length) {
      return <p>No contacts available.</p>;
    }

    return (
      <div className="bg-blue-50 p-6 rounded-lg shadow-md text-left w-full">
        <div className="flex flex-wrap justify-start gap-8 pl-0">
          {contacts.map((contact, index) => (
            <ContactCard key={index} contact={contact} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full lg:w-full">
      {/* Breadcrumbs */}
      <div className="text-gray-500 mb-4 text-left">
        <Link
          to="/dashboard/opportunities"
          className="text-blue-600 hover:text-blue-800"
        >
          Opportunities
        </Link>
        <span className="mx-2">/</span>
        <span className="text-gray-600">{selectedOpportunity.title}</span>
      </div>

      <div className="bg-white card border-0 rounded-2xl pt-4 pb-20 px-4 mb-10 w-full">
        <div className="container mx-auto p-4 mt-4">
          {/* Top Section: Title */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <h4 className="text-3xl font-bold text-blue-800">
                {selectedOpportunity.title}
              </h4>
            </div>
          </div>

          <div className="flex flex-wrap -mx-2">
            {/* Main Content */}
            <div className="w-full lg:w-2/3 px-2 mb-4">
              <h2 className="text-2xl font-bold mb-4 text-blue-800 text-left">Full Job Description</h2>
              <div className="bg-blue-50 p-6 rounded-lg shadow-md text-left w-full text-blue-800">
                <div
                  className="prose prose-sm text-gray-800"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedOpportunity.job_description) }}
                />
              </div>
            </div>

            {/* Sidebar Content */}
            <div className="w-full lg:w-1/3 px-2 mb-4 mt-4">
              <div className="bg-blue-50 p-6 rounded-lg shadow-md text-left">
                <div className="flex flex-col mb-4">
                  <div className="flex items-center mb-2">
                    <FaBuilding className="text-blue-600 mr-2" />
                    <h2 className="text-blue-600 font-bold">Company</h2>
                  </div>
                  <p className="text-black">{selectedOpportunity.company_name}</p>
                </div>

                <div className="flex flex-col mb-4">
                  <div className="flex items-center mb-2">
                    <FaMapMarkerAlt className="text-blue-600 mr-2" />
                    <h2 className="text-blue-600 font-bold">Location</h2>
                  </div>
                  <p className="text-black">{selectedOpportunity.location}</p>
                </div>

                <div className="flex flex-col mb-4">
                  <div className="flex items-center mb-2">
                    <FaBuilding className="text-blue-600  mr-2" />
                    <h2 className="text-blue-600 font-bold">Position</h2>
                  </div>
                  <p className="text-black">{selectedOpportunity.title}</p>
                </div>

                <div className="flex flex-col mb-4">
                  <div className="flex items-center mb-2">
                    <FaBuilding className="text-blue-600 mr-2" />
                    <h2 className="text-blue-600 font-bold">Duration</h2>
                  </div>
                  <p className="text-black">{selectedOpportunity.duration} months</p>
                </div>

                <div className="flex flex-col space-y-4 mb-4">
  <div className="flex items-center space-x-2 mb-2">
    <FaBuilding className="text-blue-600" />
    <h2 className="text-blue-600 font-bold">Coding Languages</h2>
  </div>
  <div className="flex flex-wrap gap-2">
    {selectedOpportunity.coding_languages.map((language, index) => (
      <span
        key={index}
        className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-semibold"
      >
        {language}
      </span>
    ))}
  </div>
</div>



                <div className="flex flex-col mb-4">
                  <div className="flex items-center mb-2">
                    <FaBuilding className="text-blue-600  mr-2" />
                    <h2 className="text-blue-600 font-bold">Type</h2>
                  </div>
                  <p className="text-black">{selectedOpportunity.contract_type}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Profile Section */}
          <div className="w-full lg:w-2/3 px-2 mb-4">
            <h2 className="text-2xl font-bold mb-2 text-blue-800 text-left">Notes</h2>
            <div className="bg-blue-50 p-6 rounded-lg shadow-md text-left">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(selectedOpportunity.notes_feedbacks),
                }}
              />
            </div>
          </div>

          {/* Potential Contacts Section */}
          <div className="w-full lg:w-2/3 px-2 mb-4">
            <h2 className="text-2xl font-bold mb-2 text-blue-800 text-left">Potential Contacts</h2>
            <PotentialContactsSection />
          </div>

          {/* Footer Section */}
          <div className="flex-grow border-t border-blue-400"></div>
          <div className="flex items-center p-4 mt-4">
            <div className="flex-grow-0 flex-shrink mr-4 text-blue-500 text-sm">
              <FaCalendarAlt className="inline mr-2 text-blue-500" /> {selectedOpportunity.month}
            </div>
            <div className="flex-grow-0 flex-shrink mr-4 text-blue-500 text-sm">
              <FaMapMarkerAlt className="inline mr-2 text-blue-500" /> {selectedOpportunity.location}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetailsPage;
