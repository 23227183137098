import React from 'react';

const OpportunityTableHead = ({ onRequestSort, order, orderBy }) => {
  const headCells = [
    { id: 'title', label: 'Title' },
    { id: 'company', label: 'Company' },
    { id: 'location', label: 'Location' },
    { id: 'type', label: 'Type' },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const columnWidth = "w-1/5";

  return (
    <thead className="bg-gray-50">
      <tr>
        {headCells.map((headCell) => (
          <th
            key={headCell.id}
            className={`px-3 py-2 text-sm font-medium text-gray-900 text-left ${columnWidth} ${headCell.id === 'location' || headCell.id === 'type' ? 'hidden md:table-cell' : ''}`}
          >
            <button onClick={createSortHandler(headCell.id)} className="focus:outline-none">
              {headCell.label}
            </button>
          </th>
        ))}
        <th className="px-3 py-2 text-right border-gray-200 w-20"> 
          <span className="sr-only">View</span>
        </th>
      </tr>
    </thead>
  );
};

export default OpportunityTableHead;
