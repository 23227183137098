import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const OpportunityTableRow = ({ opportunity, canAccessOpportunityDetails }) => {
  const { id, title, company_name, location, contract_type, url } = opportunity;

  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const history = useHistory();
  const handleViewClick = () => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleViewClickDetails = () => {
    if (canAccessOpportunityDetails) {
      history.push(`/dashboard/opportunity-details/${id}`);
    } else {
      toast.error("You do not have access to this opportunity.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const columnWidth = "w-1/5";

  return (
    <tr className="hover:bg-gray-50">
      <td className={`px-3 py-2 text-sm font-medium text-gray-900 text-left hover:text-blue-700 ${columnWidth}`}>
        {truncateText(title, 20)}
      </td>
      <td className={`px-3 py-2 text-sm text-gray-500 text-left ${columnWidth}`}>
        {truncateText(company_name, 20)}
      </td>
      <td className={`px-3 py-2 text-sm text-gray-500 text-left ${columnWidth} hidden md:table-cell`}>
        {truncateText(location, 20)}
      </td>
      <td className={`px-3 py-2 text-sm font-medium text-left ${columnWidth} hidden md:table-cell`}>
        {truncateText(contract_type, 20)}
      </td>
      <td className="py-3 whitespace-nowrap text-left text-sm font-medium w-20 pr-4">
        {url ? (
          <FontAwesomeIcon icon={faExternalLinkAlt} className="cursor-pointer text-lg" onClick={handleViewClick} />
        ) : (
          <span role="img" aria-label="View Opportunity" style={{ cursor: 'pointer' }} onClick={handleViewClickDetails}>
            👁️
          </span>
        )}
      </td>
    </tr>
  );
};

export default OpportunityTableRow;
