import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { login, fetchUserRole } from '../services/login'; // Import fetchUserRole
import 'tailwindcss/tailwind.css';

export default function LoginPage({ setIsLoggedIn, setUserRole }) { // Accept setUserRole as a prop
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleClick = async () => {
    setError(null);
    setLoading(true);

    try {
      const token = await login(username, password);
      localStorage.setItem('authToken', token);
      
      // Fetch and set the user role
      const role = await fetchUserRole();
      setUserRole(role);
      
      setIsLoggedIn(true);
      history.push('/dashboard/opportunities');
    } catch (error) {
      console.error('Login failed:', error);
      setError('Invalid username or password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Derivative Finance</title>
      </Helmet>

      <div className="min-h-screen flex justify-center items-center" style={{ background: 'linear-gradient(to right, #172554, #2563eb)' }}>
        <div className="w-full max-w-md">
          <div className="bg-white rounded-xl p-8 shadow-xl">
            <div className="mb-6 text-left">
              <img src="/assets/images/logo_orange.png" alt="Derivative Finance Logo" className="h-12 w-auto" />
            </div>

            {error && (
              <div className="mb-6 text-red-600 text-sm bg-red-100 border-l-4 border-red-500 p-4">
                {error}
              </div>
            )}

            <form>
              <div className="mb-6 text-left">
                <label htmlFor="username" className="block font-medium text-gray-700">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-4 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Enter your username"
                  required
                />
              </div>

              <div className="mb-6 relative text-left">
                <label htmlFor="password" className="block font-medium text-gray-700">Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-4 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Enter your password"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    d={showPassword ? 'M15 12a3 3 0 11-6 0 3 3 0 016 0z' : 'M9 12a3 3 0 100-6 3 3 0 000 6z'}
                  />
                </button>
              </div>

              <button
                type="button"
                onClick={handleClick}
                disabled={loading}
                className={`w-full bg-blue-600 text-white font-semibold py-3 rounded-lg transition duration-300 ease-in-out hover:bg-opacity-90 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {loading ? 'Logging in...' : 'Sign In'}
              </button>

              <p className="mt-6 text-center text-base font-medium text-gray-600">
                Don't have an account?{' '}
                <Link to="/register" className="text-sky-600 hover:text-orange-700">
                  Sign up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
