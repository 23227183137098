import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useHistory, Link } from 'react-router-dom';
import { registerUser } from '../services/login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SignUpPage() {
    const history = useHistory();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        linkedinurl: ''
    });

    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'picture') {
            if (files[0] && files[0].size > 1048576) { // 1MB size limit
                toast.error('File size should be less than 1MB');
                return;
            }
            setFile(files[0]);
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.username) newErrors.username = 'Username is required.';
        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address.';
        }
        if (!formData.first_name) newErrors.first_name = 'First name is required.';
        if (!formData.last_name) newErrors.last_name = 'Last name is required.';
        if (formData.linkedinurl) {
            if (!/^https:\/\//.test(formData.linkedinurl)) {
                newErrors.linkedinurl = 'Please enter a valid LinkedIn URL.';
            } else if (!/^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/.test(formData.linkedinurl)) {
                newErrors.linkedinurl = 'Please enter a valid LinkedIn URL.';
            }
        }
        if (!formData.password) {
            newErrors.password = 'Password is required.';
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(formData.password)) {
            newErrors.password = 'Password must contain at least 8 characters, including uppercase, lowercase, numbers, and special characters.';
        }
        if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match.';
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            toast.error('Please fix the highlighted errors.');
            return;
        }

        setLoading(true);
        const formPayload = new FormData();
        Object.keys(formData).forEach(key => {
            if (key !== 'confirmPassword') {
                formPayload.append(key, formData[key]);
            }
        });
        if (file) {
            formPayload.append('picture', file);
        }

        try {
            const result = await registerUser(formPayload);
            if (result.success) {
                toast.success('Account successfully created! You can now log in.');
                history.push('/login');
            } else {
                toast.error(`Failed to create account: ${result.error}`);
            }
        } catch (error) {
            toast.error('An error occurred during registration. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const meta = {
        title: 'Finance Derivatives | Sign Up',
    };

    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet {...meta} />
                <div className="min-h-screen flex justify-center items-center py-12 bg-gradient-to-r from-blue-600 to-blue-900">
                    <div className="w-full max-w-md px-4">
                        <div className="bg-white rounded-xl p-8 shadow-xl space-y-8">
                            <div className="mb-6 text-left space-y-2">
                                <img src="/assets/images/logo_orange.png" alt="Derivative Finance Logo" className="h-12 w-auto" />
                                <h2 className="mt-2 text-2xl font-extrabold text-gray-900">Create Your Account</h2>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="space-y-4">
                                    <div className="mb-4">
                                        <input
                                            type="text"
                                            name="username"
                                            value={formData.username}
                                            onChange={handleChange}
                                            placeholder="Username"
                                            required
                                            className={`w-full p-3 border ${errors.username ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                        />
                                        {errors.username && <span className="text-red-500 text-sm">{errors.username}</span>}
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Email"
                                            required
                                            className={`w-full p-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                        />
                                        {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            type="text"
                                            name="first_name"
                                            value={formData.first_name}
                                            onChange={handleChange}
                                            placeholder="First Name"
                                            required
                                            className={`w-full p-3 border ${errors.first_name ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                        />
                                        {errors.first_name && <span className="text-red-500 text-sm">{errors.first_name}</span>}
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            type="text"
                                            name="last_name"
                                            value={formData.last_name}
                                            onChange={handleChange}
                                            placeholder="Last Name"
                                            required
                                            className={`w-full p-3 border ${errors.last_name ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                        />
                                        {errors.last_name && <span className="text-red-500 text-sm">{errors.last_name}</span>}
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            type="text"
                                            name="phone_number"
                                            value={formData.phone_number}
                                            onChange={handleChange}
                                            placeholder="Phone Number"
                                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            type="text"
                                            name="linkedinurl"
                                            value={formData.linkedinurl}
                                            onChange={handleChange}
                                            placeholder="LinkedIn URL"
                                            className={`w-full p-3 border ${errors.linkedinurl ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                        />
                                        {errors.linkedinurl && <span className="text-red-500 text-sm">{errors.linkedinurl}</span>}
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            type="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            placeholder="Password"
                                            required
                                            className={`w-full p-3 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                        />
                                        {errors.password && <span className="text-red-500 text-sm">{errors.password}</span>}
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            placeholder="Confirm Password"
                                            required
                                            className={`w-full p-3 border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                        />
                                        {errors.confirmPassword && <span className="text-red-500 text-sm">{errors.confirmPassword}</span>}
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="mt-4 w-full bg-gradient-to-r from-blue-500 to-blue-700 text-white font-semibold py-3 rounded-lg transition duration-300 ease-in-out hover:from-blue-600 hover:to-blue-800"
                                >
                                    {loading ? 'Creating Account...' : 'Sign Up'}
                                </button>
                                <p className="mt-4 text-gray-600 text-sm text-center">
                                    Already have an account?{' '}
                                    <Link to="/login" className="text-blue-600 hover:underline">Log in</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </HelmetProvider>
            <ToastContainer />
        </React.Fragment>
    );
}
