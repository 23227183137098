import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export async function login(username, password) {
  const payload = {
      username: username,
      password: password,
  };
  console.log('payload:', payload);

  try {
      const response = await axios.post(`${API_BASE_URL}users/login/`, payload);
      if (response.data.access) {
          console.log('response:', response.data.access);
          // Store the access token
          localStorage.setItem('authToken', response.data.access);
          // Register the date of login as the last token check date
          const today = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format
          localStorage.setItem('lastCheckDate', today);
          return response.data.access;
      }
  } catch (error) {
      console.error('Login failed:', error);
      throw new Error('Login failed');
  }
};export const verifyToken = async (token) => {
    try {
      const response = await axios.post(`${API_BASE_URL}users/verify/`, { token });
      return response.data.valid;
    } catch (error) {
      console.error('Token verification failed:', error);
      return false; 
    }
  };
  export async function register(userData) {
    try {
        console.log('Register payload:', userData);
        const response = await axios.post(`${API_BASE_URL}/users/register/`, userData);
        console.log('Registration response:', response.data.message);
        return response.data.message;
    } catch (error) {
        console.error('Registration failed:', error.response.data);
        throw new Error('Registration failed:', error.response.data);
    }
};

export const registerUser = async (formPayload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}users/register/`, formPayload);
    return { success: true, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Check if the error status is 500
      if (error.response && error.response.status === 500) {
        return { success: true, data: error.response.data };
      }
      return { success: false, error: error.response?.data.message || 'An error occurred during registration.' };
    }
    return { success: false, error: 'An unknown error occurred' };
  }
};
export async function fetchUserRole() {
  const authToken = localStorage.getItem('authToken');

  if (!authToken) {
      console.log('No auth token available.');
      return { error: 'No auth token provided' };
  }

  try {
      const response = await axios.post(`${API_BASE_URL}users/role/`, {}, {
          headers: {
              Authorization: `Bearer ${authToken}`,
          },
      });
      console.log('UserRole response:', response.data);
      return response.data.role;
  } catch (error) {
      console.error('Error fetching user role:', error.response ? error.response.data : error.message);
      return { error: error.response ? error.response.data : 'Unknown error' };
  }
}
