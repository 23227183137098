import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Opportunities from './Opportunities';
import OpportunityDetailsPage from './opportunity-details';

const DashboardPage = ({ userRole }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    const handleRightClick = event => event.preventDefault();
    document.addEventListener('contextmenu', handleRightClick);
    document.body.style.userSelect = 'none';

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        document.body.style.filter = 'blur(12px)';
      } else {
        document.body.style.filter = 'none';
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      document.body.style.userSelect = '';
      document.body.style.filter = '';
    };
  }, []);

  const canAccessOpportunityDetails = userRole === 'admin' || userRole === 'subscribed_user';

  const handleAccessDenied = () => {
    toast.error("You do not have access to this opportunity.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.goBack(); // Go back to the previous page
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <ToastContainer position="top-right" />
      <div className="py-8">
        <Switch>
          <Route exact path="/dashboard" component={() => <h1 className="text-gray-500 mb-4 text-left pl-2 pb-5">Dashboard</h1>} />
          <Route path="/dashboard/opportunities" render={() => (
            <Opportunities
              navigateToTab={(id) => history.push(`/dashboard/opportunity-details/${id}`)}
              canAccessOpportunityDetails={canAccessOpportunityDetails}
              userRole={userRole}
            />
          )} />
          <Route path="/dashboard/opportunity-details/:id" render={({ match }) => (
            canAccessOpportunityDetails ? 
              <OpportunityDetailsPage handleBack={() => history.push('/dashboard/opportunities')} id={match.params.id} /> :
              handleAccessDenied()
          )} />
        </Switch>
      </div>
    </div>
  );
};

export default DashboardPage;
