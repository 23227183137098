import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { createJob, fetchJobDetails } from '../../src/services/jobs'; // Adjust the import path as needed
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddOpportunityForm = ({ closeForm }) => {
  const [formData, setFormData] = useState({
    jobTitle: '',
    company: '',
    newCompanyName: '',
    location: '',
    newCityName: '',
    industry: '',
    newIndustryName: '',
    categories: '',
    newCategoryName: '',
    contractType: '',
    duration: '',
    month: '',
    jobDescription: '',
    notesFeedbacks: '',
    languageRequirements: [],
    expectedSalary: '',
    currency: '',
    newCurrencyCode: '',
    codingLanguages: [],
    assetClass: '',
    interviewReportLink: '',
    contactPersons: [{ name: '', linkedin: '', email: '' }],
    contactInterns: [{ name: '', linkedin: '', email: '' }]
  });

  const [jobOptions, setJobOptions] = useState({
    locations: [],
    companies: [],
    industries: [],
    categories: [],
    currencies: [],
    codingLanguages: [],
    languageRequirements: []
  });

  const [showNewField, setShowNewField] = useState({
    company: false,
    location: false,
    industry: false,
    category: false,
    currency: false
  });

  useEffect(() => {
    const fetchData = async () => {
      const options = await fetchJobDetails();
      for (let key in options) {
        options[key] = options[key].sort((a, b) => a.name.localeCompare(b.name));
      }
      setJobOptions(options);
    };

    fetchData();
  }, []);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });

    if (name === 'company' || name === 'location' || name === 'industry' || name === 'categories' || name === 'currency') {
      if (value === 'other') {
        setShowNewField({ ...showNewField, [name]: true });
      } else {
        setShowNewField({ ...showNewField, [name]: false });
      }
    }
  };

  const handleMultiSelectChange = (name, selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData({ ...formData, [name]: values });
  };

  const handleContactChange = (index, event, type) => {
    const updatedContacts = formData[type].map((contact, i) =>
      i === index ? { ...contact, [event.target.name]: event.target.value } : contact
    );
    setFormData({ ...formData, [type]: updatedContacts });
  };

  const addContact = (type) => {
    setFormData({ ...formData, [type]: [...formData[type], { name: '', linkedin: '', email: '' }] });
  };

  const removeContact = (index, type) => {
    const updatedContacts = formData[type].filter((_, i) => i !== index);
    setFormData({ ...formData, [type]: updatedContacts });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await createJob(formData);
      toast.success(
        `Opportunity added successfully!
        Job Title: ${formData.jobTitle}
        Company: ${formData.company === "other" ? formData.newCompanyName : formData.company}
        Location: ${formData.location === "other" ? formData.newCityName : formData.location}`
      );      
      setTimeout(closeForm, 2000); 
    } catch (error) {
      toast.error('Failed to create opportunity.');
      console.error('Failed to create job:', error);
    }
  };

  const mapOptions = (options) => options.map(option => ({ value: option.id, label: option.name }));

  return (
    <>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl w-full h-3/4 overflow-y-auto">
          <form onSubmit={handleSubmit} className="space-y-6">
            <h2 className="text-3xl font-bold text-center text-blue-500">Add Opportunity</h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div className="col-span-1 sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-700">Job Title <span className="text-red-500">*</span></label>
                <input
                  type="text"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter job title"
                  required
                />
              </div>

              <div className="col-span-1 sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-700">Company Name</label>
                <Select
                  name="company"
                  options={[...mapOptions(jobOptions.companies), { value: 'other', label: 'Other' }]}
                  onChange={(selectedOption) => handleChange('company', selectedOption ? selectedOption.value : '')}
                  className="w-full"
                  isSearchable
                />
                {showNewField.company && (
                  <input
                    type="text"
                    name="newCompanyName"
                    value={formData.newCompanyName}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Add a new company if it's not listed"
                  />
                )}
              </div>

              <div className="col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-700">Location</label>
                <Select
                  name="location"
                  options={[...mapOptions(jobOptions.locations), { value: 'other', label: 'Other' }]}
                  onChange={(selectedOption) => handleChange('location', selectedOption ? selectedOption.value : '')}
                  className="w-full"
                  isSearchable
                />
                {showNewField.location && (
                  <input
                    type="text"
                    name="newCityName"
                    value={formData.newCityName}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Add a new city if it's not listed"
                  />
                )}
              </div>

              <div className="col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-700">Industry</label>
                <Select
                  name="industry"
                  options={[...mapOptions(jobOptions.industries), { value: 'other', label: 'Other' }]}
                  onChange={(selectedOption) => handleChange('industry', selectedOption ? selectedOption.value : '')}
                  className="w-full"
                  isSearchable
                />
                {showNewField.industry && (
                  <input
                    type="text"
                    name="newIndustryName"
                    value={formData.newIndustryName}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Add a new industry if it's not listed"
                  />
                )}
              </div>

              <div className="col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-700">Categories</label>
                <Select
                  name="categories"
                  options={[...mapOptions(jobOptions.categories), { value: 'other', label: 'Other' }]}
                  onChange={(selectedOption) => handleChange('categories', selectedOption ? selectedOption.value : '')}
                  className="w-full"
                  isSearchable
                />
                {showNewField.categories && (
                  <input
                    type="text"
                    name="newCategoryName"
                    value={formData.newCategoryName}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Add a new category if it's not listed"
                  />
                )}
              </div>

              <div className="col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-700">Contract Type</label>
                <Select
                  name="contractType"
                  options={[
                    { value: 'remote', label: 'Remote' },
                    { value: 'full time', label: 'Full Time' },
                    { value: 'part time', label: 'Part Time' }
                  ]}
                  onChange={(selectedOption) => handleChange('contractType', selectedOption ? selectedOption.value : '')}
                  className="w-full"
                  isSearchable
                />
              </div>

              <div className="col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-700">Duration (Months)</label>
                <input
                  type="number"
                  name="duration"
                  step="any"
                  value={formData.duration}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter duration in months"
                />
              </div>

              <div className="col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-700">Month</label>
                <Select
                  name="month"
                  options={[
                    { value: '01', label: 'January' },
                    { value: '02', label: 'February' },
                    { value: '03', label: 'March' },
                    { value: '04', label: 'April' },
                    { value: '05', label: 'May' },
                    { value: '06', label: 'June' },
                    { value: '07', label: 'July' },
                    { value: '08', label: 'August' },
                    { value: '09', label: 'September' },
                    { value: '10', label: 'October' },
                    { value: '11', label: 'November' },
                    { value: '12', label: 'December' }
                  ]}
                  onChange={(selectedOption) => handleChange('month', selectedOption ? selectedOption.value : '')}
                  className="w-full"
                  isSearchable
                />
              </div>

              <div className="col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-700">Expected Salary</label>
                <input
                  type="number"
                  name="expectedSalary"
                  step="any"
                  value={formData.expectedSalary}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter expected salary"
                />
              </div>

              <div className="col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-700">Currency</label>
                <Select
                  name="currency"
                  options={[...mapOptions(jobOptions.currencies), { value: 'other', label: 'Other' }]}
                  onChange={(selectedOption) => handleChange('currency', selectedOption ? selectedOption.value : '')}
                  className="w-full"
                  isSearchable
                />
                {showNewField.currency && (
                  <input
                    type="text"
                    name="newCurrencyCode"
                    value={formData.newCurrencyCode}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Add a new currency if it's not listed"
                  />
                )}
              </div>

              <div className="col-span-1 sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-700">Language Requirements</label>
                <Select
                  name="languageRequirements"
                  options={mapOptions(jobOptions.languageRequirements)}
                  isMulti
                  onChange={(selectedOptions) => handleMultiSelectChange('languageRequirements', selectedOptions)}
                  className="w-full"
                  isSearchable
                />
              </div>

              <div className="col-span-1 sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-700">Coding Languages</label>
                <Select
                  name="codingLanguages"
                  options={mapOptions(jobOptions.codingLanguages)}
                  isMulti
                  onChange={(selectedOptions) => handleMultiSelectChange('codingLanguages', selectedOptions)}
                  className="w-full"
                  isSearchable
                />
              </div>

              <div className="col-span-1 sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-700">Job Description</label>
                <textarea
                  name="jobDescription"
                  value={formData.jobDescription}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter job description"
                ></textarea>
              </div>

              <div className="col-span-1 sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-700">Notes and Feedbacks</label>
                <textarea
                  name="notesFeedbacks"
                  value={formData.notesFeedbacks}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter notes and feedbacks"
                ></textarea>
              </div>

              <div className="col-span-1 sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-700">Interview Report Link</label>
                <input
                  type="text"
                  name="interviewReportLink"
                  value={formData.interviewReportLink}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter interview report link"
                />
              </div>

              <div className="col-span-1 sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-700">Contact Persons</label>
                {formData.contactPersons.map((person, index) => (
                  <div key={index} className="space-y-2 mb-4">
                    <input
                      type="text"
                      name="name"
                      value={person.name}
                      onChange={(event) => handleContactChange(index, event, 'contactPersons')}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter contact person name"
                    />
                    <input
                      type="text"
                      name="linkedin"
                      value={person.linkedin}
                      onChange={(event) => handleContactChange(index, event, 'contactPersons')}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter contact person LinkedIn"
                    />
                    <input
                      type="email"
                      name="email"
                      value={person.email}
                      onChange={(event) => handleContactChange(index, event, 'contactPersons')}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter contact person email"
                    />
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeContact(index, 'contactPersons')}
                        className="w-full bg-red-500 text-white p-2 rounded-md mt-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => addContact('contactPersons')}
                  className="w-full bg-blue-500 text-white p-2 rounded-md mt-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Add Contact Person
                </button>
              </div>

              <div className="col-span-1 sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-700">Contact Interns</label>
                {formData.contactInterns.map((intern, index) => (
                  <div key={index} className="space-y-2 mb-4">
                    <input
                      type="text"
                      name="name"
                      value={intern.name}
                      onChange={(event) => handleContactChange(index, event, 'contactInterns')}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter contact intern name"
                    />
                    <input
                      type="text"
                      name="linkedin"
                      value={intern.linkedin}
                      onChange={(event) => handleContactChange(index, event, 'contactInterns')}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter contact intern LinkedIn"
                    />
                    <input
                      type="email"
                      name="email"
                      value={intern.email}
                      onChange={(event) => handleContactChange(index, event, 'contactInterns')}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter contact intern email"
                    />
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeContact(index, 'contactInterns')}
                        className="w-full bg-red-500 text-white p-2 rounded-md mt-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => addContact('contactInterns')}
                  className="w-full bg-blue-500 text-white p-2 rounded-md mt-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Add Contact Intern
                </button>
              </div>
            </div>

            <div className="flex justify-between mt-6">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Add Opportunity
              </button>
              <button
                type="button"
                onClick={closeForm}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

export default AddOpportunityForm;
