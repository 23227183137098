import React from "react";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Correct the import statement
import {
  UserIcon,
  NewspaperIcon,
  AcademicCapIcon,
  CalendarDaysIcon,
  TrophyIcon,
  BookOpenIcon,
  ScaleIcon,
  DocumentTextIcon,
  ArrowRightOnRectangleIcon,
  ClockIcon, // For the 'in development' icon
  BriefcaseIcon,
} from "@heroicons/react/24/outline";

const Sidebar = ({ currentTab }) => {
  const [userData, setUserData] = React.useState({
    name: "Loading...",
    username: "Fetching...",
    email: "",
    picture: "/assets/images/default_avatar.png"
  });

  const location = useLocation();

  React.useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserData({
          name: `${decoded.first_name} ${decoded.last_name}`,
          username: decoded.username,
          email: decoded.email,
          picture: "/assets/images/avatar_1.jpg"
        });
      } catch (error) {
        console.error("Failed to decode JWT:", error);
      }
    }
  }, []);

  const onLogout = () => {
    localStorage.removeItem("authToken");
    window.location.href = "/login";
  };

  const navigationItems = [
    { icon: BriefcaseIcon, name: "Opportunities", route: "/dashboard/opportunities", comingSoon: false },
    { icon: UserIcon, name: "Dashboard", route: "/dashboard", comingSoon: true },
    { icon: NewspaperIcon, name: "News Feed", route: "/news", comingSoon: true },
    { icon: AcademicCapIcon, name: "Test & Train", route: "/test", comingSoon: true },
    { icon: CalendarDaysIcon, name: "Events & Meetings", route: "/events", comingSoon: true },
    { icon: TrophyIcon, name: "XP & Rewards", route: "/rewards", comingSoon: true },
    { icon: BookOpenIcon, name: "Resources", route: "/resources", comingSoon: true },
    { icon: DocumentTextIcon, name: "Ebook", comingSoon: true }
  ];

  const isRouteActive = (route) => {
    if (route === "/dashboard/opportunities") {
      return location.pathname.startsWith(route) || location.pathname.startsWith("/dashboard/opportunity-details");
    }
    return location.pathname === route;
  };

  return (
    <aside className="bg-white border-r-2 border-gray-200 shadow-lg mt-8 mb-8 ml-8 mr-4 rounded-xl">
      <nav className="flex flex-col p-6">
        {/* User information and avatar */}
        <div className="flex flex-col items-center mb-10">
          <div className="w-20 h-20 bg-blue-100 rounded-full mb-3 flex items-center justify-center overflow-hidden">
            <img src={userData.picture} alt="User" className="w-full h-full object-cover" />
          </div>
          <div className="text-center">
            <p className="text-sm font-semibold text-gray-700">{userData.name}</p>
            <p className="text-xs text-gray-500">{userData.username}</p>
            <p className="text-xs text-gray-500">{userData.email}</p>
          </div>
        </div>

        {navigationItems.map((item) =>
          item.comingSoon ? (
            <div
              key={item.route}
              className="flex items-center px-4 py-2 mt-4 w-full text-left rounded-lg text-blue-400 cursor-not-allowed"
            >
              <item.icon className="h-6 w-6 mr-3" />
              <ClockIcon className="h-4 w-4 mr-1 text-yellow-500" />
              {item.name.split(' ')[0]} (Soon)
            </div>
          ) : (
            <div
              onClick={() => { if (!isRouteActive(item.route)) window.location.href = item.route; }}
              key={item.route}
              className={`flex items-center px-4 py-2 mt-4 w-full text-left rounded-lg transition duration-300 cursor-pointer ${
                isRouteActive(item.route) ? 'bg-blue-100 text-blue-800' : 'text-gray-400 hover:bg-gray-100'
              }`}
              aria-current={isRouteActive(item.route) ? 'page' : undefined}
            >
              <item.icon className={`h-6 w-6 mr-3 ${isRouteActive(item.route) ? 'text-blue-900' : 'text-blue-200'}`} />
              {item.name}
            </div>
          )
        )}

        {/* Logout Button */}
        <button
          onClick={onLogout}
          className="mt-4 px-4 py-2 w-full text-left text-red-500 hover:bg-gray-100 rounded-lg flex items-center"
        >
          <ArrowRightOnRectangleIcon className="h-6 w-6 mr-3" />
          Logout
        </button>
      </nav>
    </aside>
  );
};

export default Sidebar;
