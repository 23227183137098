import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { verifyToken, fetchUserRole } from './services/login'; // Updated import to include fetchUserRole
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './layouts/Header';
import Sidebar from './layouts/Sidebar';
import MainContent from './layouts/MainContent';
import Footer from './layouts/Footer';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/signup';
import ErrorBoundary from './ErrorBoundary'; // Import the ErrorBoundary component

import './App.css';

// Loading Screen Component with Tailwind CSS
const LoadingScreen = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-16 h-16 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
    </div>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null); // Add a state for user role
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Add a state for sidebar visibility
  const history = useHistory();

  useEffect(() => {
    const checkToken = async () => {
      setIsLoading(true); // Start loading
      const token = localStorage.getItem('authToken');
      if (token) {
        const isValid = await verifyToken(token);
        if (isValid) {
          const role = await fetchUserRole(); 
          setUserRole(role); // Set the user role
          setIsLoggedIn(true);
        }
      }
      setIsLoading(false); // Stop loading
    };
    checkToken();

    // Check screen size to set initial sidebar visibility
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarVisible(false);
      } else {
        setIsSidebarVisible(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoading) {
    return <LoadingScreen />; // Display loading screen while loading
  }

  return (
    <BrowserRouter>
      <HelmetProvider>
        <div className="App flex flex-col min-h-screen bg-blue-50 overflow-hidden">
          {isLoggedIn ? (
            <>
              <Header onLogout={() => {
                setIsLoggedIn(false);
                setUserRole(null); // Clear user role on logout
                localStorage.removeItem('authToken');
                history.push('/login');
              }} />
              <div className="flex flex-1 flex-col md:flex-row overflow-hidden">
                {isSidebarVisible && (
                  <div className="hidden md:block flex-none ">
                    <Sidebar />
                  </div>
                )}
                <div className="flex-1 overflow-auto">
                  <MainContent>
                    <Switch>
                      <Route path="/dashboard">
                        <DashboardPage userRole={userRole} /> {/* Pass userRole to DashboardPage */}
                      </Route>
                    </Switch>
                  </MainContent>
                </div>
              </div>
              {/* <Footer /> */}
            </>
          ) : (
            <Switch>
              <Route path="/login">
                <LoginPage setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} /> {/* Pass setUserRole to LoginPage */}
              </Route>
              <Route path="/register">
              <ErrorBoundary>
                <SignUpPage />
                </ErrorBoundary>
              </Route>
              <Route path="/">
                <LoginPage setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} /> {/* Pass setUserRole to LoginPage */}
              </Route>
            </Switch>
          )}
        </div>
      </HelmetProvider>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar closeOnClick pauseOnHover draggable pauseOnFocusLoss />
    </BrowserRouter>
  );
};

export default App;
