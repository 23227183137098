import React from 'react';

const TableNoData = ({ colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan} className="text-center py-10 text-sm font-normal text-gray-500">
        <div className="flex flex-col items-center justify-center space-y-4">
          <span className="material-icons text-5xl text-gray-300">table_chart</span>
          <span className="text-lg font-semibold text-gray-600">No opportunities available</span>
          <p className="text-sm text-gray-500">Please check back later or adjust your filters.</p>
        </div>
      </td>
    </tr>
  );
};

export default TableNoData;
